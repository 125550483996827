import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProduct from "@/vue/domain/product/df-product";
import DfStore from "@/vue/domain/store/df-store";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfGroup from "@/vue/domain/group/df-group";
import { Carousel, Slide } from "vue-carousel-variable-width";
const DfCardProductComponent = () => Utils.externalComponent2("df-card-product");

@Component({
  components: {
    DfCardProduct: DfCardProductComponent,
    Carousel,
    Slide,
  },
})
export default class DfProductDetailsRelatedProductsComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  product!: DfProduct;
  @Prop({ type: Object, default: null })
  promotion: DfPromotion;
  @Prop({ type: String, default: "smt-digitalflyer-ui" })
  origin: string;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get productUniqueId(): Array<string> {
    let productUniqueId: Array<string> = [];
    const groups: Array<DfGroup> = this.$store.getters.promotionGroups;
    groups.forEach((group: DfGroup) => {
      if (this.product.groups.indexOf(group.code) > -1) {
        const filteredUniqueId: Array<string> = group.productsUniqueId.filter((uniqueId: string) => uniqueId !== this.product.uniqueId);
        productUniqueId = [...productUniqueId, ...filteredUniqueId];
      }
    });
    return productUniqueId;
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  get relatedProducts(): Array<DfProduct> {
    return this.cachedProducts.filter((product: DfProduct) => this.productUniqueId.indexOf(product.uniqueId) > -1);
  }

  beforeMount() {
    this.loadProductsList();
  }

  private loadProductsList() {
    if (this.productUniqueId.length > 0 && this.productUniqueId.length !== this.relatedProducts.length) {
      this.$root.$emit("loadCachedProducts", this.productUniqueId, 10);
    }
  }
}
